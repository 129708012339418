// store.js
import {create} from 'zustand';
import appConfig from 'configs/app.config'


export const SchemaWorkSelect = create((set, get) => ({

    _EschemeWork: JSON.parse(localStorage.getItem('_EschemeWork')) || { value: appConfig.schemeWorkLower, label: appConfig.schemeWorkUpper },
    _setEschemeWork: (valor) => {
      set(() => ({ _EschemeWork: valor }));
      localStorage.setItem('_EschemeWork', JSON.stringify(valor));
    },
     
}))

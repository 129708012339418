const appConfig = {
    apiPrefix: 'https://api-autocontplus.sisplani.com',
    authenticatedEntryPath: '/conciliacion-bancaria',//ruta de entrada autenticada -> si se logeo correctamente
    unAuthenticatedEntryPath: '/access-denied',//Ruta de entrada no autenticada -> si no esta autorizado
    AuthenticatedSignOff: '/sign-in',//Ruta de entrada no autenticada -> si no esta autorizado

    tourPath: '/app/account/kyc-form',
    locale: 'en',
    enableMock: false,

    sisplaniApi: 'https://api-autocontplus.sisplani.com',
    cuentasxCobrarSoles : '1720',
    cuentasxPagarSoles : '1739',
    cuentasxPagarDolaresMonedaOriginal:'1740',
    cuentasxPagarDolaresMonedaOriginalSoles:'1740',

    schemeWorkUpper:'Modelo',//
    schemeWorkLower:'modelo',//

    timeoutApiSisplani:5000,

    


}

export default appConfig
